import React, { useState } from "react";
import axios from "axios";
import { Button, Container, Row, Col } from "react-bootstrap";
import defaultImage from "../../assets/images/defaultImage.png";

function FacialRecognitionLocal() {
    const [imageDataBase64, setimageDataBase64] = useState([]);
    const [imageDataArrayBuffer, setImageDataArrayBuffer] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [dataLoad, setDataLoad] = useState(false);

    //Submitting local files is a little trickier than remote.
    //Local files need to be translated to base64 via a ArrayBuffer.
    //But as this takes time, it needs to be done asynchronously to prevent the api being called with an empty data set.
    async function Base64Encoder(imageToTranslate) {
        if (imageToTranslate)
            return new Promise((resolve, reject) => {
                var reader = new FileReader();

                reader.onload = () => {
                    setimageDataBase64(reader.result);
                    resolve(imageDataBase64);
                };

                reader.onerror = () => {
                    reject(reader.result);
                };
                reader.readAsDataURL(imageToTranslate);
            });
    }
    async function ImageToArrayBuffer(imageToTranslate) {
        if (imageToTranslate)
            return new Promise((resolve, reject) => {
                var reader = new FileReader();

                reader.onload = () => {
                    setImageDataArrayBuffer(reader.result);
                    resolve(imageDataBase64);
                };

                reader.onerror = () => {
                    reject(reader.result);
                };
                reader.readAsArrayBuffer(imageToTranslate);
            });
    }
    function SubmitImage() {
        const api = axios.create({
            baseURL: `https://l4rcy128lc.execute-api.us-east-1.amazonaws.com/standard/face-recognition`,
        });

        // Perform the REST API call.

        api.post("/local/", imageDataBase64)
            .then(function (response) {
                // Display the image by translating the data to a blob and displaying it in the querySelector.
                var blob = new Blob([imageDataArrayBuffer]);
                var url = URL.createObjectURL(blob);
                setDataLoad(true);
                document.querySelector("#sourceLocalImage").src = url;
                console.log("Status text: " + response.status);
                console.log("Status text: " + response.statusText);

                console.log(response.data);
                setResponseData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        console.log(imageDataBase64);
    }

    let localValue = responseData.map((e) => JSON.stringify(e, null, 2));
    // let localValue = "testing 4 ever.";
    return (
        <div>
            <Container fluid="xl">
                <Row>
                    <Col xs="auto">
                        <p>
                            Select a local image, and then click the{" "}
                            <b>Analyze face button.</b>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col x="auto">
                        <label>Image to analyze: </label>
                        <input
                            type="file"
                            name="inputLocalImage"
                            id="inputLocalImage"
                            autoComplete="off"
                            accept="image/png, image/jpeg"
                            onChange={(e) => {
                                Base64Encoder(e.target.files[0]);
                                ImageToArrayBuffer(e.target.files[0]);
                            }}
                            style={{
                                width: "60%",
                                marginLeft: "1vw",
                                marginRight: "1vw",
                            }}
                        />
                        <Button variant="info" onClick={() => SubmitImage()}>
                            Analyze face
                        </Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: "2vw" }}>
                    <Col xs="6">
                        <label>
                            <strong>Source Image</strong>
                        </label>
                    </Col>
                    <Col xs="auto">
                        <label>
                            <strong>Response</strong>
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        {dataLoad ? (
                            <img
                                id="sourceLocalImage"
                                alt=""
                                style={
                                    dataLoad
                                        ? { width: "80%" }
                                        : { display: "none" }
                                }
                            />
                        ) : (
                            <img
                                id="defaultImage"
                                src={defaultImage}
                                alt=""
                                style={
                                    dataLoad
                                        ? { display: "none" }
                                        : { width: "80%" }
                                }
                            />
                        )}
                    </Col>
                    <Col>
                        {dataLoad ? (
                            <textarea
                                id="responseTextArea"
                                className="UIInput"
                                style={{ width: "100%", height: "429px" }}
                                value={localValue}
                                readOnly={true}
                                disabled="disabled"></textarea>
                        ) : (
                            <textarea
                                id="responseTextArea"
                                className="UIInput"
                                style={
                                    dataLoad
                                        ? { display: "none" }
                                        : { width: "100%", height: "auto" }
                                }
                                defaultValue="This field will display analyzed data on the image"
                                readOnly={true}
                                disabled="disabled"></textarea>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default FacialRecognitionLocal;
