// This is the tab based control system for the facial verification modal
// it's purpose is to provide a tabbed display to allow users to select
// between local and remote image scanning

import React, { useState } from "react";

import { Container, Tabs, Tab } from "react-bootstrap";
import FacialRecognitionLocal from "./FacialRecognitionLocal";
import FacialRecognitionRemote from "./FacialRecognitionRemote";
import "bootstrap/dist/css/bootstrap.min.css";
function FacialRecognitionContainer() {
    const [key, setKey] = useState("local");

    return (
        <Container fluid="xl">
            <Tabs
                id="facial-recognition-tab-controller"
                activeKey={key}
                onSelect={(k) => setKey(k)}>
                <Tab eventKey="local" title="Local">
                    <FacialRecognitionLocal />
                </Tab>
                <Tab eventKey="remote" title="Remote">
                    <FacialRecognitionRemote />
                </Tab>
            </Tabs>
        </Container>
    );
}

export default FacialRecognitionContainer;
