import React, { useState } from "react";
import axios from "axios";
import defaultImage from "../../assets/images/defaultImage.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Container, Row, Col } from "react-bootstrap";

function FacialRecognitionRemote() {
    const [url, setURL] = useState("");
    const [imageData, setImageData] = useState([]);
    const [dataLoad, setDataLoad] = useState(false);

    //For remote images, the api only requires the link. The dataLoad value is only used to check that data has been submitted,
    //Allowing the correct field to conditionally render.
    function ProcessImage(imageUrl) {
        const api = axios.create({
            baseURL: `https://rtu1ypgugf.execute-api.us-east-1.amazonaws.com/production/face-recognition`,
        });
        var websiteName = encodeURIComponent(imageUrl);
        var w = websiteName.toString();

        api.post("/remote/" + w)
            .then(function (response) {
                // Display the image.
                var sourceImageRemoteUrl = document.getElementById(
                    "inputRemoteImage"
                ).value;
                setDataLoad(true);
                document.querySelector(
                    "#sourceImageRemote"
                ).src = sourceImageRemoteUrl;
                console.log("Status text: " + response.status);
                console.log("Status text: " + response.statusText);

                console.log(response.data);
                setImageData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    let remoteValue = imageData.map((e) => JSON.stringify(e, null, 2));
    return (
        <div>
            <Container fluid="xl">
                <Row>
                    <Col xs="auto">
                        <p>
                            Enter the URL to an image that includes a face or
                            faces, then click the <b>Analyze face button.</b>
                        </p>
                        <p style={{ fontSize: "12px" }}>
                            <i>
                                Please note: Images linked must be larger than{" "}
                                <b>36x36 pixels</b>, and smaller than
                                <b>1920x1080 pixels</b>.
                            </i>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col x="auto">
                        <label>Image to analyze: </label>
                        <input
                            type="text"
                            name="inputRemoteImage"
                            id="inputRemoteImage"
                            placeholder="https://.../ExampleImage.jpg"
                            autoComplete="off"
                            onChange={(e) => setURL(e.target.value)}
                            style={{
                                width: "60%",
                                marginLeft: "1vw",
                                marginRight: "1vw",
                            }}
                        />
                        <Button
                            variant="info"
                            onClick={() => ProcessImage(url)}>
                            Analyze face
                        </Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: "2vw" }}>
                    <Col xs="6">
                        <label>
                            <strong>Response</strong>
                        </label>
                    </Col>
                    <Col xs="auto">
                        <label>
                            <strong>Source Image</strong>
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {dataLoad ? (
                            <textarea
                                id="responseTextAreaRemote"
                                className="UIInput"
                                style={{ width: "100%", height: "429px" }}
                                value={remoteValue}
                                readOnly={true}
                                disabled="disabled"></textarea>
                        ) : (
                            <textarea
                                id="responseTextAreaRemote"
                                className="UIInput"
                                style={
                                    dataLoad
                                        ? { display: "none" }
                                        : { width: "100%", height: "auto" }
                                }
                                defaultValue="This field will display analyzed data on the image"
                                readOnly={true}
                                disabled="disabled"></textarea>
                        )}
                    </Col>
                    <Col xs="6">
                        {dataLoad ? (
                            <img
                                id="sourceImageRemote"
                                alt=""
                                style={
                                    dataLoad
                                        ? { width: "80%" }
                                        : { display: "none" }
                                }
                            />
                        ) : (
                            <img
                                id="defaultImage"
                                src={defaultImage}
                                alt=""
                                style={
                                    dataLoad
                                        ? { display: "none" }
                                        : { width: "80%" }
                                }
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default FacialRecognitionRemote;
