import React from "react";
import { Button } from "react-bootstrap";
import { Auth } from "aws-amplify";

function JSignOut() {
    const signOut = async () => {
        await Auth.signOut();
        window.location.reload();
    };
    return (
        <div>
            <Button variant="outline-info" onClick={signOut}>
                Sign Out
            </Button>
        </div>
    );
}

export default JSignOut;
